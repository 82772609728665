import * as React from "react";
import { View, Text, Field, Button } from "components/elementsThemed";
import { Loader } from "components/fragments";
import { withTemplate } from "components/hocs";
import { Copy, Routes } from "utils";
import { stringReplacer } from "utils/helpers/string";
import {
  mapTimeZone,
  initDate,
  setUnixTimestampDownToMinute,
} from "utils/helpers/time";
import { SET_SCHEDULE_DATE } from "utils/api";
import useResource from "../../../../hooks/useResource";
import useForm from "../../../../hooks/useForm";

import css from "./index.module.scss";
import { BaseLocation } from "../../../../@types/location";

const { Select } = Field;

type ScheduleableDate = {
  pretty: string;
  unixTimestamp: string;
};

type FullSchedulableDate = {
  formatted: string;
  day: string;
  time: string;
  daysOfWeek: string;
  isAvailable: boolean;
  capacity: number;
} & ScheduleableDate;

interface ScheduleDateType2 {
  order: {
    location: BaseLocation;
    order: {
      diningOption: OrderType;
      scheduledAt: number;
    };
    setScheduledAt: (at: number | null) => void;
    items: any;
  };
  style: {
    views: {
      background: string;
    };
    inputs: {
      standard: string;
    };
    buttons: {
      alternate: string;
      footer: string;
    };
    labels: {
      description: string;
    };
  };
  setHeader: (h: string) => void;
  onSuccess: () => void;
}

function ScheduleDate({
  order: {
    location,
    order: { diningOption, scheduledAt },
    setScheduledAt,
    items,
  },
  style: { views, inputs, buttons, labels },
  setHeader,
  onSuccess,
}: ScheduleDateType2) {
  const { values, errors, updateField, updateError } = useForm({
    initialValues: {
      day: scheduledAt ? initDate(scheduledAt, location.timeZone) : "",
      time: scheduledAt || "",
    },
  });
  const [times, setTimes] = React.useState<FullSchedulableDate[]>([]);
  const timeZone = location.timeZone
    ? `${Copy.CART_STATIC.DISPLAYED_TIME_ZONE} ${mapTimeZone(
        location.timeZone,
      )}`
    : "";

  const [
    {
      resource: days = [],
      fetching: dayFetching,
      // error: dayError,
    },
  ] = useResource<ScheduleableDate[]>({
    data: {
      orderType: diningOption,
    },
    path: Routes.FETCH_LOCATIONS_SCHEDULE_DATE,
    method: "get",
  });

  const handleSuccess = () => {
    if (!values.time) {
      updateError("time", Copy.CART_STATIC.SCHEDULED_DATE_INVALID_TIME);
    } else {
      setScheduledAt(values.time as number);
      onSuccess();
    }
  };
  const handleClear = () => {
    setScheduledAt(null);
    onSuccess();
  };

  React.useEffect(() => {
    if (setHeader) {
      setHeader(
        stringReplacer(Copy.CART_STATIC.SCHEDULE_DINING_DATE_HEADER, [
          { replaceTarget: "{diningOption}", replaceValue: diningOption },
        ]),
      );
    }
  }, []);

  React.useEffect(() => {
    if (!values.day) return;

    (async () => {
      try {
        const { data }: { data: FullSchedulableDate[] } =
          await SET_SCHEDULE_DATE({
            data: {
              orderType: diningOption,
              orderAmount: items.reduce((prev, curr) => prev + curr.price, 0),
            },
            date: values.day,
            config: {},
          });

        const mappedTimes = data.map((i) => ({
          ...i,
          unixTimestamp: `${setUnixTimestampDownToMinute(
            // eslint-disable-next-line radix
            parseInt(i.unixTimestamp),
          )}`,
        }));
        setTimes(mappedTimes);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [values.day]);

  if (dayFetching) {
    return (
      <View type={views.background} className={css.scheduleDate}>
        <div className={css.loader}>
          <Loader />
        </div>
      </View>
    );
  }

  return (
    <View type={views.background} className={css.scheduleDate}>
      <div className={css["scheduleDate-container"]}>
        <div className={css["scheduleDate-fields"]}>
          <Select
            className={css["scheduleDate-fields--select"]}
            type={inputs.standard}
            label={Copy.CART_STATIC.SCHEDULED_DATE_DAY_LABEL}
            error={errors.day}
            value={values.day}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateField("day", e.target.value)
            }
          >
            <>
              <option value="">
                {Copy.CART_STATIC.SCHEDULED_DATE_DAY_PLACEHOLDER}
              </option>

              {days.map((i) => (
                <option key={i.unixTimestamp} value={i.unixTimestamp}>
                  {i.pretty}
                </option>
              ))}
            </>
          </Select>
          <Select
            className={css["scheduleDate-fields--select"]}
            type={inputs.standard}
            label={Copy.CART_STATIC.SCHEDULED_DATE_TIME_LABEL}
            disabled={!days}
            error={errors.time}
            value={values.time}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateField("time", e.target.value)
            }
          >
            <>
              <option value="">
                {Copy.CART_STATIC.SCHEDULED_DATE_TIME_PLACEHOLDER}
              </option>

              {times.map(({ isAvailable = true, ...i }) => (
                <option
                  key={i.unixTimestamp}
                  value={i.unixTimestamp}
                  disabled={!isAvailable}
                >
                  {i.formatted}
                </option>
              ))}
            </>
          </Select>
        </div>
        <div className={css["scheduleDate-label"]}>
          <Text type={labels.description}>{timeZone}</Text>
        </div>
        <Button
          type={buttons.alternate}
          className={css["scheduleDate-button"]}
          htmlType="button"
          disabled={!days.length || !times.length || !values.time}
          block
          onClick={handleSuccess}
        >
          {stringReplacer(Copy.CART_STATIC.SCHEDULED_DATE_CONFIRM_TIME, [
            { replaceTarget: "{orderType}", replaceValue: diningOption },
          ])}
        </Button>
      </div>

      <div className={css["scheduleDate-footer"]}>
        <Button
          block
          className={css["scheduleDate-footer--button"]}
          type={buttons.footer}
          onClick={handleClear}
        >
          {Copy.CART_STATIC.SCHEDULED_DATE_ASAP_ORDER}
        </Button>
      </div>
    </View>
  );
}

const ScheduledDateType2 = withTemplate(ScheduleDate, "scheduleDate");
ScheduledDateType2.displayName = "ScheduledDateType2";

export default ScheduledDateType2;
